@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700;800&display=swap');

* {
	font-family: 'Poppins', sans-serif !important;
	padding: auto;
}

@media (max-width: 600px) {
	* {
		font-family: 'Plus Jakarta Sans', sans-serif !important;
		padding: auto;
	}
}

html,
body {
	height: 100vh;
}

.page-wrapper {
	min-height: '100vh' !important;
}

.job-list::-webkit-scrollbar {
	display: none;
}

.job-list {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

@media (max-width: 430px) {
	.nav-mobile-link {
		padding-left: 10px !important;
		padding-right: 10px !important;
		padding-top: 14px !important;
	}

	.button-group-text {
		font-size: 12px !important;
	}

	.login__mob-heading {
		padding-left: 25px !important;
		padding-right: 25px !important;
	}
}

@media (max-width: 320px) {
	.button-group-text {
		font-size: 9px !important;
	}
}

.backgroundAnimate {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background-color: #098edb56;
}

.site-rtc-editor .ck.ck-editor__main {
	height: 300px !important;
}

.site-rtc-editor .ck.ck-content.ck-editor__editable {
	height: 100% !important;
}

.blog-image {
	max-width: 100% !important;
}

.container-image img {
	width: 100%;
	max-height: 680px !important;
	object-fit: contain !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}
