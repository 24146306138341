.backdrop {
	width: 100%;
	height: 100vh;
	z-index: 12;
	background: rgb(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}
