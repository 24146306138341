.admin-chat {
	width: 360px;
	box-shadow: 0px 0px 5px #ccc;
	position: fixed;
	bottom: 0px;
	right: 10px;
}

.admin-chat .top-bar {
	padding: 10px;
	width: 100%;
	background-color: #0062ff;
	display: flex;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	color: #fff;
	align-items: center;
	justify-content: space-between;
}
.admin-chat .messages {
	height: 300px;
	overflow-y: auto;
	padding: 0px;
	margin: 0px;
	background: #fff;
	color: #ccc;
	position: relative;
}

.admin-chat .user-list {
	height: 400px;
	overflow-y: auto;
	padding: 0px;
	margin: 0px;
	background: #fff;
	color: #ccc;
}

.admin-chat .user-list li {
	cursor: pointer !important;
}

.admin-chat .messages .message-item,
.admin-chat .user-list li {
	list-style: none;
	padding: 10px;
	border-bottom: 1px solid #f7f3f3;
}

.admin-chat .messages .message-item .message-title,
.admin-chat .user-list li .message-title {
	font-size: 0.8rem;
}

.admin-chat .messages .message-item .message-name,
.admin-chat .user-list li .message-name {
	font-size: 0.5rem;
	color: rgb(124, 124, 124);
}

.admin-chat .message-container {
	position: relative;
}

.admin-chat .message-container .load-more,
.admin-chat .user-list-container .load-more {
	position: absolute;
	font-size: 0.8rem;
	color: #fff;
	top: 13px;
	left: 130px;
	padding: 9px;
	background: #0062ff;
	border-radius: 26px;
	width: 90px;
	height: 26px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.admin-chat .user-list-container .load-more {
	top: unset;
	bottom: 13px;
}
.admin-chat .bottom-bar {
	padding: 10px;
	border-top: 1px solid #0062ff;
	background: #fff;
	display: flex;
	align-items: center;
}

.admin-chat .bottom-bar .message-input-box {
	padding: 10px;
	border: none;
	background-color: #fff;
	margin-right: 5px;
	border-bottom: 1px solid #ccc;
	width: 226px;
}

.admin-chat .bottom-bar .message-send-btn {
	padding: 10px 20px;
	margin-right: 5px;
	outline: none;
	border: none;
	background-color: #0062ff;
	color: #fff;
	border-radius: 10px;
	cursor: pointer;
}

.admin-chat .bottom-bar .message-send-btn:disabled {
	background-color: rgb(140, 140, 140);
}

.admin-chat .bottom-bar .message-upload-file-btn {
	color: #0062ff;
	cursor: pointer !important;
}

.admin-chat .bottom-bar .message-upload-file-btn svg {
	font-size: 1.9rem;
	margin-top: 5px;
	cursor: pointer !important;
}
.user-list-container {
	position: relative;
}
